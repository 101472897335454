<template>
  <div class="container">
    <div class="row">
      <div class="col-9">
        <h1>alerts</h1>
      </div>
      <div class="col-3 text-right">
        <router-link
          :to="{ name: 'alerts.add' }"
          class="btn btn-outline-success btn-sm"
        >
          <icon type="plus" /> Add New Provider
        </router-link>
      </div>
    </div>
    <alert v-if="alerts.isLoading" class="shadow-card" />
    <alert
      v-if="!alerts.isLoading && alerts.data.length == 0"
      class="shadow-card"
    >
      No alerts,
      <router-link :to="{ name: 'alerts.add' }"
        >Add a new alert here</router-link
      >
    </alert>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "calendar-alerts",
  computed: {
    ...mapState(["alerts"]),
  },
  components: {},
};
</script>
